import * as React from 'react'

const Disclaimer = () => {
    return (
        <div id="content">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <p className="text-muted">
                    Advocacy.biz by Capitol Enquiry hereby grants you access to
                    https://www.advocacy.biz (“the Website”) and invites you to
                    purchase the services offered here.
                  </p>

                  <h3 className="text-muted"></h3>
                  <h3 className="text-muted">Definitions and key terms</h3>
                  <p className="text-muted">
                    To help explain things as clearly as possible in this
                    Disclaimer, every time any of these terms are referenced,
                    are strictly defined as:
                  </p>

                  <ul className="text-muted">
                    <li>
                      Cookie: small amount of data generated by a website and
                      saved by your web browser. It is used to identify your
                      browser, provide analytics, remember information about you
                      such as your language preference or login information.
                    </li>
                    <li>
                      Company: when this policy mentions “Company,” “we,” “us,”
                      or “our,” it refers to Capitol Enquiry, (1034 Emerald Bay
                      Rd #435, South Lake Tahoe, CA 96150), that is responsible
                      for your information under this Disclaimer.
                    </li>
                    <li>
                      Service: refers to the service provided by Advocacy.biz by
                      Capitol Enquiry as described in the relative terms (if
                      available) and on this platform.
                    </li>
                    <li>
                      Website: Advocacy.biz by Capitol Enquiry’s site, which can
                      be accessed via this URL: https://www.advocacy.biz
                    </li>
                    <li>
                      You: a person or entity that is registered with
                      Advocacy.biz by Capitol Enquiry to use the Services.
                    </li>
                  </ul>
                  <h3 className="text-muted"></h3>
                  <h3 className="text-muted">Limited liability</h3>
                  <p className="text-muted">
                    Advocacy.biz by Capitol Enquiry endeavours to update and/or
                    supplement the content of the website on a regular basis.
                    Despite our care and attention, content may be incomplete
                    and/or incorrect.
                  </p>
                  <p className="text-muted">
                    The materials offered on the website are offered without any
                    form of guarantee or claim to their correctness. These
                    materials can be changed at any time without prior notice
                    from Advocacy.biz by Capitol Enquiry.
                  </p>
                  <p className="text-muted">
                    Particularly, all prices on the website are stated subject
                    to typing and programming errors. No liability is assumed
                    for the implications of such errors. No agreement is
                    concluded on the basis of such errors.
                  </p>
                  <p className="text-muted">
                    Advocacy.biz by Capitol Enquiry shall not bear any liability
                    for hyperlinks to websites or services of third parties
                    included on the website. From our website, you can visit
                    other websites by following hyperlinks to such external
                    sites. While we strive to provide only quality links to
                    useful and ethical websites, we have no control over the
                    content and nature of these sites. These links to other
                    websites do not imply a recommendation for all the content
                    found on these sites. Site owners and content may change
                    without notice and may occur before we have the opportunity
                    to remove a link which may have gone ‘bad’.
                  </p>
                  <p className="text-muted">
                    Please be also aware that when you leave our website, other
                    sites may have different privacy policies and terms which
                    are beyond our control. Please be sure to check the Privacy
                    Policies of these sites as well as their "Terms of Service"
                    before engaging in any business or uploading any
                    information.
                  </p>

                  <h3 className="text-muted"></h3>
                  <h3 className="text-muted">Links to Other Websites Disclaimer</h3>
                  <p className="text-muted">
                    This Disclaimer applies only to the Services. The Services
                    may contain links to other websites not operated or
                    controlled by Advocacy.biz by Capitol Enquiry. We are not
                    responsible for the content, accuracy or opinions expressed
                    in such websites, and such websites are not investigated,
                    monitored or checked for accuracy or completeness by us.
                    Please remember that when you use a link to go from the
                    Services to another website, our Privacy Policy is no longer
                    in effect. Your browsing and interaction on any other
                    website, including those that have a link on our platform,
                    is subject to that website’s own rules and policies. Such
                    third parties may use their own cookies or other methods to
                    collect information about you. If You click on a third party
                    link, You will be directed to that third party's site. We
                    strongly advise You to review the Privacy Policy and Terms
                    of every site You visit.
                  </p>
                  <p className="text-muted">
                    We have no control over and assume no responsibility for the
                    content, privacy policies or practices of any third party
                    sites or services.
                  </p>

                  <h3 className="text-muted"></h3>
                  <h3 className="text-muted">Errors and Omissions Disclaimer</h3>
                  <p className="text-muted">
                    Advocacy.biz by Capitol Enquiry is not responsible for any
                    content, code or any other imprecision.
                  </p>
                  <p className="text-muted">
                    Advocacy.biz by Capitol Enquiry does not provide warranties
                    or guarantees.
                  </p>
                  <p className="text-muted">
                    In no event shall Advocacy.biz by Capitol Enquiry be liable
                    for any special, direct, indirect, consequential, or
                    incidental damages or any damages whatsoever, whether in an
                    action of contract, negligence or other tort, arising out of
                    or in connection with the use of the Service or the contents
                    of the Service. Advocacy.biz by Capitol Enquiry reserves the
                    right to make additions, deletions, or modifications to the
                    contents on the Service at any time without prior notice.
                  </p>

                  <h3 className="text-muted"></h3>
                  <h3 className="text-muted">General Disclaimer</h3>
                  <p className="text-muted">
                    The Advocacy.biz by Capitol Enquiry Service and its contents
                    are provided "as is" and "as available" without any warranty
                    or representations of any kind, whether express or implied.
                    Advocacy.biz by Capitol Enquiry is a distributor and not a
                    publisher of the content supplied by third parties; as such,
                    Advocacy.biz by Capitol Enquiry exercises no editorial
                    control over such content and makes no warranty or
                    representation as to the accuracy, reliability or currency
                    of any information, content, service or merchandise provided
                    through or accessible via the Advocacy.biz by Capitol
                    Enquiry Service. Without limiting the foregoing,
                    Advocacy.biz by Capitol Enquiry specifically disclaims all
                    warranties and representations in any content transmitted on
                    or in connection with the Advocacy.biz by Capitol Enquiry
                    Service or on sites that may appear as links on the
                    Advocacy.biz by Capitol Enquiry Service, or in the products
                    provided as a part of, or otherwise in connection with, the
                    Advocacy.biz by Capitol Enquiry Service, including without
                    limitation any warranties of merchantability, fitness for a
                    particular purpose or non-infringement of third party
                    rights. No oral advice or written information given by
                    Advocacy.biz by Capitol Enquiry or any of its affiliates,
                    employees, officers, directors, agents, or the like will
                    create a warranty. Price and availability information is
                    subject to change without notice. Without limiting the
                    foregoing, Advocacy.biz by Capitol Enquiry does not warrant
                    that the Advocacy.biz by Capitol Enquiry Service will be
                    uninterrupted, uncorrupted, timely, or error-free.
                  </p>

                  <h3 className="text-muted"></h3>
                  <h3 className="text-muted">Copyright Disclaimer</h3>
                  <p className="text-muted">
                    All intellectual property rights concerning these materials
                    are vested in Advocacy.biz by Capitol Enquiry. Copying,
                    distribution and any other use of these materials is not
                    permitted without the written permission of Advocacy.biz by
                    Capitol Enquiry, except and only to the extent otherwise
                    provided in regulations of mandatory law (such as the right
                    to quote), unless otherwise stated for certain materials.
                  </p>

                  <h3 className="text-muted"></h3>
                  <h3 className="text-muted">Educational Disclosure</h3>
                  <p className="text-muted">
                    Any Information provided by Advocacy.biz by Capitol Enquiry
                    is for educational purposes only, and is not to be
                    interpreted as a recommendation for a specific treatment
                    plan, product, or course of action. Advocacy.biz by Capitol
                    Enquiry is a distributor and not a publisher of the content
                    supplied by third parties; as such, Advocacy.biz by Capitol
                    Enquiry exercises no editorial control over such content and
                    makes no warranty or representation as to the accuracy,
                    reliability or currency of any information or educational
                    content provided through or accessible via Advocacy.biz by
                    Capitol Enquiry. Without limiting the foregoing,
                    Advocacy.biz by Capitol Enquiry specifically disclaims all
                    warranties and representations in any content transmitted on
                    or in connection with Advocacy.biz by Capitol Enquiry or on
                    sites that may appear as links on Advocacy.biz by Capitol
                    Enquiry, or in the products provided as a part of, or
                    otherwise in connection with, the Advocacy.biz by Capitol
                    Enquiry. No oral advice or written information given by
                    Advocacy.biz by Capitol Enquiry or any of its affiliates,
                    employees, officers, directors, agents, or the like will
                    create a warranty.
                  </p>

                  <h3 className="text-muted"></h3>
                  <h3 className="text-muted">Testimonials Disclosure</h3>
                  <p className="text-muted">
                    Any testimonials provided on this platform are opinions of
                    those providing them. The information provided in the
                    testimonials is not to be relied upon to predict results in
                    your specific situation. The results you experience will be
                    dependent on many factors including but not limited to your
                    level of personal responsibility, commitment, and abilities,
                    in addition to those factors that you and/or Advocacy.biz by
                    Capitol Enquiry may not be able to anticipate.
                  </p>
                  <p className="text-muted">
                    We will give honest testimonials to our visitors regardless
                    of any discount. Any product or service that we test are
                    individual experiences, reflecting real life experiences.
                    The testimonials could be displayed on audio, text or video
                    and are not necessarily representative of all of those who
                    will use our products and/or services.
                  </p>
                  <p className="text-muted">
                    Advocacy.biz by Capitol Enquiry does not guarantee the same
                    results as the testimonials given on our platform.
                    Testimonials presented on Advocacy.biz by Capitol Enquiry
                    are applicable to the individuals writing them, and may not
                    be indicative of future success of any other individuals.
                  </p>
                  <p className="text-muted">
                    Please don’t hesitate to contact us if you would like to
                    know more about testimonials, discounts, or any of the
                    products/services that we review.
                  </p>

                  <h3 className="text-muted"></h3>
                  <h3 className="text-muted">Your Consent</h3>
                  <p className="text-muted">
                    We've updated our Disclaimer to provide you with complete
                    transparency into what is being set when you visit our site
                    and how it's being used. By using our website, registering
                    an account, or making a purchase, you hereby consent to our
                    Disclaimer and agree to its terms.
                  </p>

                  <h3 className="text-muted"></h3>
                  <h3 className="text-muted">Changes To Our Disclaimer</h3>
                  <p className="text-muted">
                    Should we update, amend or make any changes to this document
                    so that they accurately reflect our Service and policies.
                    Unless otherwise required by law, those changes will be
                    prominently posted here. Then, if you continue to use the
                    Service, you will be bound by the updated Disclaimer. If you
                    do not want to agree to this or any updated Disclaimer, you
                    can delete your account.
                  </p>

                  <h3 className="text-muted"></h3>
                  <h3 className="text-muted">Contact Us</h3>
                  <p className="text-muted">
                    Don't hesitate to contact us if you have any questions
                    regarding this Disclaimer.
                  </p>

                  <ul className="text-muted">
                    <li>Via Email: info@advocacy.biz</li>
                    <li>Via Phone Number: 9163020000</li>
                    <li>
                      Via this Address: 1034 Emerald Bay Rd #435, South Lake
                      Tahoe, CA 96150
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
    )
}

export default Disclaimer
